.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Set-header {
  background-color: #fab232;
  height: 6rem;
}

.nav-header {
  background-color: #fab232;
  height: fit-content;
}

.Nav-bar {
  background-color: #5e5a5a;
  height: 3rem;
}

.nav-active {
  font-weight: bold;
  /* text-decoration: underline !important; */
  color: white;
}

@media (max-width: 1500px) {
  .table-fixed {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.table-header {
  background-color: #5e5a5a;
  color: white;
  border-bottom: '3px solid #5e5a5a';
}

.col-2-5 {
  flex: 0 0 auto;
  width: 20.835% !important;
}

.is-link {
  cursor: pointer;
}

.input-align-center {
  text-align: -webkit-center;
}

.line-container {
  width: 1000px;
  /* margin: 100px auto;  */
}

/*line*/
#line {
  margin-bottom: 30px;
  margin-top: 36px;
  /* overflow: hidden; */
  counter-reset: step;
  text-align: center;
}

#line li {
  list-style-type: none;
  color: black;
  /* text-transform: uppercase; */
  font-size: 15px;
  /* font-weight: bold; */
  width: 15%;
  float: left;
  position: relative;
}

#line li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 36px;
  display: block;
  color: black;
  /* background:    gray; */
  border-radius: 50%;
  border: 2px solid #fbb034;
  margin: 0 auto 5px auto;
}

/*line connectors*/
#line li:after {
  content: '';
  width: 73%; /* Changed */
  height: 2px;
  background: #fbb034;
  position: absolute;
  left: -36%; /* Changed */
  top: 20px;
  z-index: 1; /* Changed */
}

#line li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps*/
/*The number of the step and the connector before it = fbb034*/
#line li.active:before,
#line li.active:after {
  background: #fbb034;
  color: black;
  font-size: 15px;
  font-weight: bold;
}

#line li.passed:before,
#line li.passed:after {
  background: #f3d789;
  color: black;
  font-size: 15px;
  font-weight: bold;
}
#line li.reject:before,
#line li.reject:after {
  background: #f15656;
  color: black;
  font-size: 15px;
  font-weight: bold;
}

#line li.inactive:before,
#line li.inactive:after {
  background: gray;
  color: black;
  font-size: 15px;
  font-weight: bold;
}

#text li {
  list-style-type: none;
  width: 15%;
  float: left;
  position: relative;
}

#text li:before {
  content: counter(step);
  color: white;
}


.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
}

.rbt-aux .rbt-close {
  margin-top: -0.25rem;
  pointer-events: auto;
}
.rbt-aux .rbt-close {
  pointer-events: auto;
}

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
