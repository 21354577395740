* {
  font-family: 'Arial';
}

html {
  font-size: 11pt;
}

.navbar {
  font-size: 20px;
}
.navbar-expand .navbar-nav .dropdown-menu {
  font-size: 20px;
}

.title {
  font-size: 22px;
  font-weight: 500;
}
