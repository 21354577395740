.custom-nav {
  background-color: rgb(94, 90, 90) !important;
  padding: 0px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: bold;
  text-decoration: white;
}

.navbar-expand .navbar-nav .dropdown-menu {
  font-weight: bold;
}
