body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.separate-line {
  border-bottom: 1.5px solid #dee2e6 !important;
  border-color: #ffc107 !important;
}

.DRAFT,
.REJECTED,
.APPROVED,
.MD_REJECTED,
.MD_APPROVED,
.MD_ACKNOWLEDGE,
.PENDING,
.COMPLETED {
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  color: white !important;
  text-align: center;
}

.DRAFT {
  background-color: #6c757d !important;
}
.REJECTED,
.MD_REJECTED {
  background-color: #dc3545 !important;
}
.APPROVED,
.MD_APPROVED,
.MD_ACKNOWLEDGE,
.COMPLETED {
  background-color: #198754 !important;
}

.PENDING {
  background-color: #ffc107 !important;
}

.title {
  font-size: 22px;
  font-weight: 500;
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
}

.input-align-center {
  text-align: -webkit-center;
}
